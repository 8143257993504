<template>
  <v-dialog v-model="dialog" max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        depressed
        rounded
        color="primary"
        style="float: right"
        v-bind="attrs"
        v-on="on"
        >Add tag</v-btn
      >
    </template>
    <v-card>
      <v-form @submit.prevent="saveTag" ref="tagForm">
        <v-row no-gutters class="my-0 py-0">
          <v-spacer></v-spacer
          ><v-card-actions>
            <v-btn icon @click="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-actions>
        </v-row>
        <v-card-title>
          <v-row class="mx-2">Add tag</v-row>
        </v-card-title>
        <v-card-text>
          <v-alert text color="red" type="error" v-if="errorMsg">
            {{ errorMsg }}
          </v-alert>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="newItem.name"
                  :rules="[inputRequired, minTagCharsRule, maxTagCharsRule]"
                  label="Tag name"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click="close"> Cancel </v-btn>
          <v-btn color="primary" text @click="saveTag"> Save </v-btn>
          <v-btn color="primary" text @click="saveTagGo"> Save & Go </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { EventBus } from "@/utils";
import { NEW_CREATED_TAG } from "@/utils/events";
import { CREATE_TAG } from "@/store/modules/tag/actions";

export default {
  name: "TagAddDialog",
  data() {
    return {
      dialog: false,
      itemsLength: [],
      items: [],
      newItem: {
        name: null,
      },
      defaultItem: {
        name: null,
      },
      errorMsg: "",
      inputRequired: (v) => !!v || v === 0 || "Required field",
      requiredRule: (v) => !!v || "Not enough characters to save tags",
      minTagCharsRule: (v) => {
        if (!(v instanceof Array)) {
          return (v && v.length >= 2) || "Input too short!";
        } else {
          const tooShortItems = v.filter(function (item) {
            return item.length < 2;
          });
          return tooShortItems.length === 0 || "Input too short!";
        }
      },
      maxTagCharsRule: (v) => {
        if (!(v instanceof Array)) {
          return (v && v.length <= 40) || "Input too long!";
        } else {
          const tooLongItems = v.filter(function (item) {
            return item.length > 40;
          });
          return tooLongItems.length === 0 || "Input too long!";
        }
      },
    };
  },
  watch: {
    dialog(val) {
      this.errorMsg = null;
      val || this.close();
    },
  },
  methods: {
    close() {
      this.dialog = false;
      this.errorMsg = null;
      this.$refs.tagForm.resetValidation();
      this.newItem = Object.assign({}, this.defaultItem);
    },
    saveTagGo() {
      let vm = this;
      return this.createTag(function (data) {
        vm.errorMsg = null;
        vm.$refs.tagForm.resetValidation();
        vm.newItem = Object.assign({}, vm.defaultItem);
        EventBus.$emit(NEW_CREATED_TAG, data.id);
      });
    },
    saveTag() {
      let vm = this;
      return this.createTag(function (data) {
        vm.close();
        EventBus.$emit(NEW_CREATED_TAG, data.id);
      });
    },
    createTag(resolve) {
      let vm = this;
      if (!vm.$refs.tagForm.validate()) {
        this.errorMsg = "Please check all fields!";
      } else {
        this.$store
          .dispatch(`tag/${CREATE_TAG}`, this.newItem)
          .then(resolve, function (error) {
            vm.errorMsg = error;
          })
          .catch(() => {
            this.errorMsg = "Something went wrong";
          });
      }
    },
  },
};
</script>
