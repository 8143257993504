<template>
  <div>
    <div>
      <v-dialog v-model="deleteError" max-width="500px" align="center">
        <v-card>
          <v-alert prominent text type="error">{{ errorMsg }}</v-alert>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="deleteError = !deleteError"
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-actions class="py-0">
            <v-spacer></v-spacer>
            <v-btn icon @click="closeDelete">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-actions>
          <v-alert text color="red" type="error" v-if="errorMsg">
            {{ errorMsg }}
          </v-alert>
          <v-card-title
            >Are you sure you want to delete this item?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey" text @click="closeDelete">Cancel</v-btn>
            <v-btn color="primary" text @click="deleteItemConfirm">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div>
      <v-dialog v-model="editDialog" max-width="500px">
        <v-card>
          <v-form @submit.prevent="putEditItem" ref="tagForm">
            <v-row no-gutters class="my-0 py-0">
              <v-spacer></v-spacer
              ><v-card-actions>
                <v-btn icon @click="clearEditDialog">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-actions>
            </v-row>
            <v-card-title>
              <v-row class="mx-2">Edit</v-row>
            </v-card-title>
            <v-card-text>
              <v-alert text color="red" type="error" v-if="errorMsg">
                {{ errorMsg }}
              </v-alert>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="editedItem.name"
                      required
                      :rules="[inputRequired, minCharsRule, maxCharsRule]"
                      label="Tag name"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="grey darken-1" text @click="clearEditDialog">
                Cancel
              </v-btn>
              <v-btn color="primary" text @click="putEditItem"> Save </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </div>
    <h1 class="outline--text">
      System tags
      <tag-add-dialog v-if="canManage" class="mt-4"></tag-add-dialog>
    </h1>
    <tag-search class="pt-5"></tag-search>
    <v-row>
      <v-col class="outline--text mt-7" style="max-width: 700px">
        <div>
          <span v-if="!isLoading && this.totalCount > 0">
            <p v-if="this.totalCount >= this.currentPage * this.itemsPerPage">
              Showing {{ (this.currentPage - 1) * this.itemsPerPage + 1 }}-{{
                this.currentPage * this.itemsPerPage
              }}
              of {{ this.totalCount }} items
            </p>
            <p v-else>
              Showing {{ (this.currentPage - 1) * this.itemsPerPage + 1 }}-{{
                this.totalCount
              }}
              of {{ this.totalCount }} items
            </p>
          </span>
        </div>
      </v-col>
      <v-col>
        <div class="d-flex justify-space-between">
          <v-spacer></v-spacer>
          <v-pagination
            v-if="showPages && pageCount > 1"
            class="my-3"
            v-model="pagination.page"
            :length="pageCount"
            :total-visible="7"
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
          ></v-pagination>
          <div class="d-flex mx-10">
            <v-select
              v-model="pagination.rowPerPage"
              item-text="label"
              item-value="attribute"
              :items="countPerPage"
              label="Show items"
              style="max-width: 80px"
            >
            </v-select>
          </div>
          <div class="d-flex">
            <v-select
              item-text="label"
              item-value="attribute"
              :items="sortAttributes"
              label="sortBy"
              v-model="sort.sortBy"
              style="max-width: 180px"
            ></v-select>
            <span v-if="sort.sortBy" class="mt-5 ml-2">
              <v-icon
                v-if="sort.desc"
                title="sortAsc"
                @click="sort.desc = false"
              >
                mdi-sort-descending
              </v-icon>
              <v-icon v-else title="sortDesc" @click="sort.desc = true">
                mdi-sort-ascending
              </v-icon>
            </span>
            <v-btn
              depressed
              rounded
              small
              outlined
              icon
              color="grey darken-1"
              style="float: right"
              class="my-5 mx-6"
              @click="printItems"
              ><v-icon> mdi-printer-outline </v-icon></v-btn
            >
            <v-btn
              depressed
              rounded
              small
              outlined
              icon
              color="grey darken-1"
              style="float: right"
              class="my-5"
              @click="exportItems"
              ><v-icon> mdi-file-export-outline </v-icon></v-btn
            >
          </div>
        </div>
      </v-col>
    </v-row>
    <v-data-table
      id="itemsTable"
      :headers="headers"
      :loading="isLoading"
      :items="tags"
      :items-per-page="5"
      disable-pagination
      disable-filtering
      disable-sort
      hide-default-footer
      class="mx-0"
      :item-class="itemClass"
    >
      <template v-slot:item.name="{ item }">
        <v-icon class="mr-2" small> mdi-tag </v-icon>
        {{ item.name }}
      </template>
      <template v-slot:item.Actions="{ item }">
        <v-icon v-if="canManage" class="mr-2" @click="editItem(item)">
          mdi-pencil-outline
        </v-icon>
        <v-icon v-if="canManage" color="red" @click="deleteItem(item)">
          mdi-delete-forever-outline
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters, mapState } from "vuex";
import BaseListPage from "./base/BaseListPage";

import TagSearch from "@/views/tag/list/TagSearch";
import TagAddDialog from "@/views/tag/item/TagAddDialog";

import { NEW_CREATED_TAG } from "@/utils/events";
import { EventBus } from "@/utils";
import {
  DELETE_TAG,
  FETCH_TAG,
  FETCH_TAG_NAMES,
  FETCH_TAGS,
  FETCH_TAGS_FILE,
  SET_EMPTY_FILTERS,
  SET_FILTERS,
  UPDATE_TAG,
} from "@/store/modules/tag/actions";
import {
  defaultFilters,
  emptyFilters,
  multipleFilters,
} from "@/store/modules/tag/state";
import { SET_DEFAULT_TAG_FILTERS } from "@/store/modules/persistentStorage/actions";
import acl from "@/mixin/acl";
import { RESOURCE_TAGS_MANAGEMENT } from "@/store/modules/admin/getters";

export default {
  name: "TagsPage",
  mixins: [acl],
  extends: BaseListPage,
  components: {
    TagSearch,
    TagAddDialog,
  },
  data() {
    return {
      defaultFilters,
      emptyFilters,
      multipleFilters,
      editedIndex: -1,
      editedItem: {},
      defaultItem: {},
      dialogDelete: false,
      deleteError: false,
      showPages: false,
      countPerPage: [
        {
          attribute: 20,
          label: "20",
        },
        {
          attribute: 50,
          label: "50",
        },
        {
          attribute: 100,
          label: "100",
        },
        {
          attribute: 5000,
          label: "All",
        },
      ],
      headers: [
        {
          text: "Id",
          value: "id",
          width: "50",
        },
        {
          text: "Tag Name",
          value: "name",
          width: "250",
        },
        {
          text: "Sources Count",
          value: "sourcesCount",
          width: "100",
        },
        {
          text: "Channels Count",
          value: "channelsCount",
          width: "100",
        },
        {
          text: "Created",
          value: "createdAt",
          width: "200",
          align: "center",
        },
        {
          text: "",
          value: "Actions",
          width: "90",
        },
      ],
      sortAttributes: [
        {
          attribute: "id",
          label: "ID",
        },
        {
          attribute: "name",
          label: "Name",
        },
        {
          attribute: "sourcesCount",
          label: "Sources Count",
        },
        {
          attribute: "channelsCount",
          label: "Channels Count",
        },
        {
          attribute: "createdAt",
          label: "Date Created",
        },
      ],
      errorMsg: "",
      inputRequired: (v) => !!v || "Required field",
      booleanRequired: (v) => !!v || v !== false || v !== 0 || "Required field",
      minCharsRule: (v) => (v && v.length >= 3) || "Input too short!",
      maxCharsRule: (v) => (v && v.length <= 40) || "Input too long!",
      requiredRule: (v) => !!v || "Not enough characters to save the tag",
    };
  },
  computed: {
    ...mapGetters("tag", [
      "tagList",
      "totalCount",
      "currentPage",
      "pageCount",
      "offset",
      "formattedFilters",
    ]),
    ...mapState("tag", ["sort", "pagination", "filters"]),
    canManage: function () {
      return this.hasAccess([RESOURCE_TAGS_MANAGEMENT]);
    },
    itemsPerPage: function () {
      return this.pagination.rowPerPage;
    },
    tags: function () {
      if (this.isLoading) {
        return [];
      }
      return this.tagList;
    },
  },
  watch: {
    tagList: function () {},
    "pagination.rowPerPage": function () {
      this.pagination.page = 1;
    },
  },
  methods: {
    copyName(item) {
      navigator.clipboard.writeText(item.url);
    },
    clearEditDialog() {
      this.errorMsg = "";
      this.closeEditDialog();
    },
    applyFilters: function (payload) {
      return this.$store.dispatch(`tag/${SET_FILTERS}`, payload.data);
    },
    fetchItems: function () {
      const vm = this;
      vm.isLoading = true;
      vm.$store.dispatch(`tag/${FETCH_TAG_NAMES}`);
      vm.$store.dispatch(`tag/${FETCH_TAGS}`).then(
        () => {
          vm.isLoading = false;
          vm.showPages = true;
        },
        (error) => {
          console.log(error);
        }
      );
    },
    fetchItem: function (itemId, how) {
      const vm = this;
      vm.$store.dispatch(`tag/${FETCH_TAG_NAMES}`);
      vm.$store.dispatch(`tag/${FETCH_TAG}`, { itemId, how }).then(
        () => {},
        (error) => {
          console.log(error);
        }
      );
    },
    editItem: function (item) {
      this.errorMsg = "";
      this.editedItem = _.merge({}, item);
      this.editDialog = true;
    },
    // editStatus(item) {
    //   this.editedItem = _.merge({}, item);
    //   this.putEditItem();
    // },
    putEditItem() {
      if (this.editDialog) {
        if (!this.$refs.tagForm.validate()) {
          this.errorMsg = "Please check all fields!";
          return;
        }
      }
      const vm = this;
      let { editedItem } = this;
      let { id } = editedItem;
      vm.$store
        .dispatch(`tag/${UPDATE_TAG}`, {
          id,
          editedItem,
        })
        .then(
          () => {
            vm.fetchItem(this.editedItem.id);
            if (this.editDialog) {
              this.clearEditDialog();
            }
          },
          (error) => {
            this.errorMsg = error;
          }
        );
    },
    closeDelete() {
      this.dialogDelete = false;
      this.selectedItem = null;
    },
    deleteItem(item) {
      this.errorMsg = "";
      this.selectedItem = item;
      this.dialogDelete = true;
    },
    deleteItemConfirm: function () {
      let vm = this;
      vm.$store
        .dispatch(`tag/${DELETE_TAG}`, vm.selectedItem)
        .then(
          function () {
            if (vm.tagList.length === 0) {
              vm.$store.dispatch(`tag/${SET_EMPTY_FILTERS}`);
              vm.$store.dispatch(
                `persistentStorage/${SET_DEFAULT_TAG_FILTERS}`
              );
              vm.fetchItems();
            } else {
              vm.$store.dispatch(`tag/${FETCH_TAG_NAMES}`);
            }
          },
          function (error) {
            vm.errorMsg = error.details;
            vm.deleteError = true;
          }
        )
        .catch(() => {
          vm.errorMsg = "Something went wrong";
          vm.deleteError = true;
        });
      vm.dialogDelete = false;
    },
    exportItems: function () {
      const vm = this;
      vm.$store.dispatch(`tag/${FETCH_TAGS_FILE}`).then(
        () => {},
        (error) => {
          console.log(error);
        }
      );
    },
    printItems: function () {
      this.$htmlToPaper("itemsTable");
    },
  },
  mounted() {
    const vm = this;
    vm.applyFilters({
      data: _.merge({}, vm.defaultFilters),
    });
    vm.pagination.page = 1;
    vm.applyRouteParams();
    vm.fetchItems();
  },
  created() {
    EventBus.$on(NEW_CREATED_TAG, (tagId) => {
      this.fetchItem(tagId, "push");
    });
  },
};
</script>
